import * as React from 'react'
import ReactModal from 'react-modal'

import { style, StyledContent } from './modal-container.style'

export interface Props extends ReactModal.Props {
  pcWidth?: React.CSSProperties['width']
  pcHeight?: React.CSSProperties['height']
  pcMinHeight?: React.CSSProperties['minHeight']
  onClose?: () => void
  mobileFullLayout?: boolean
  mergeStyle?: ReactModal.Styles
  isRenderDefault?: boolean // ModalContainer 사용하는 측에서 이중 상태 변경하지 않도록 제공
  containerClassName?: string
  contentWrapperClassName?: string
}

export const animationMS = 200

ReactModal.setAppElement('body')

const ModalContainer = (props: Props) => {
  const {
    isOpen,
    pcWidth,
    pcHeight,
    pcMinHeight,
    children,
    onClose,
    mobileFullLayout = true,
    mergeStyle,
    isRenderDefault = false,
    contentWrapperClassName,
    containerClassName,
    ...modalProps
  } = props
  const [isRender, setIsRender] = React.useState(isRenderDefault) // modal 라이브러리 특성상 바로 오픈할 경우 body class ReactModal__Body--open 안없어지는 현상 있음
  const mergedStyle = React.useMemo<ReactModal.Styles>(
    () => (mergeStyle ? { overlay: { ...style.overlay, ...mergeStyle.overlay } } : style),
    [mergeStyle],
  )
  React.useEffect(() => {
    if (!isRender) {
      setIsRender(true)
    }
    // 최초 렌더 한번만 사용
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ReactModal
      className={containerClassName}
      isOpen={isRender && isOpen}
      style={mergedStyle}
      onRequestClose={onClose}
      closeTimeoutMS={animationMS}
      contentElement={(props, children) => (
        <StyledContent
          {...props}
          className={contentWrapperClassName}
          mobileFullLayout={!!mobileFullLayout}
          style={{
            width: pcWidth,
            height: pcHeight,
            minHeight: pcMinHeight,
            ...mergeStyle?.content,
          }}>
          {children}
        </StyledContent>
      )}
      {...modalProps}>
      {children}
    </ReactModal>
  )
}

export default ModalContainer
